import { Directive, ElementRef, HostListener, Input, Renderer2, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appTruncate]'
})
export class TruncateDirective implements OnChanges {
  @Input() appTruncate!: number; // Longitud máxima (configurable)
  @Input() text!: string; // Texto original (para expansión/contracción)

  private isTruncated = true; // Estado inicial: truncado

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['text']) { // Aplica truncado cuando el texto cambie
      this.applyTruncate();
    }
  }

  // Escucha el clic para alternar entre truncado y expandido
  @HostListener('click')
  toggleTruncate() {
    this.isTruncated = !this.isTruncated;
    this.applyTruncate();
  }

  private applyTruncate() {
    if (!this.text) { // Asegura que no intentemos truncar si el texto está vacío
      return;
    }

    const needsEllipsis = this.text.length > this.appTruncate;
    const displayedText = this.isTruncated && needsEllipsis
      ? this.text.slice(0, this.appTruncate) + (this.text.length > this.appTruncate ? '...' : '')
      : this.text;

    this.renderer.setProperty(this.el.nativeElement, 'innerText', displayedText);

    // Agregar o eliminar clase según sea necesario
    // Usabilidad: diferenciamos en DOM+CSS los textos con elipsis y dejamos los demás textos intactos
    if (needsEllipsis) {
      this.renderer.addClass(this.el.nativeElement, 'has-ellipsis');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'has-ellipsis');
    }
  }
}
