import { Component, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-stats-data-item',
  templateUrl: './stats-data-item.component.html',
  styleUrls: ['./stats-data-item.component.scss']
})

export class StatsDataItemComponent {
  @Input() item: any;
  @Input() eventId: any;
  @Input() iAmAdmin: boolean;

  constructor(
    private auth: AuthenticationService,
    private http: HttpClient
  ) {}

  downloadFile(fileUrl, fileName) {
    const token = this.auth.currentUserValue.accessToken;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http.get(fileUrl, { headers, responseType: 'blob' }).subscribe((response) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }
}
