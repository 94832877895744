import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DeployEnvironment } from '../../environments/enums/deployEnvironment.enum';

// Definición de la interfaz para los PRODUCTOS de PLATAFORMA UNBLOCK
interface PlatformProduct {
  id: number;
  product: string;
  priceWithoutIva?: number;
  iva: number;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  constructor() { }
}

// Tipos de PRODUCTOS de PLATAFORMA
const PLATFORM_PRODUCTS: PlatformProduct[] = [
  { id: 2, product: 'mkt-pro', priceWithoutIva: 120, iva: 1.21 },
  { id: 3, product: 'mkt-premium', priceWithoutIva: 360, iva: 1.21 },
  { id: 4, product: 'mkt-balance', iva: 1.21 },
  { id: 5, product: 'mkt-slots-upgrade-50', iva: 1.21 },
];

// URLs de PRODUCCIÓN
const PRODUCTION_URLS = {
  api: 'https://panelapi.unblockthecity.com',
  cdn: 'https://cdn.unblockthecity.com',
};

// URLs de STAGE
const STAGE_URLS = {
  api: 'https://devpanelapi.unblockthecity.com',
  cdn: 'https://cdn.unblockthecity.com',
};

// URLs de DESARROLLO
const DEV_URLS = {
  api: 'https://apipanel-develop.unblockthecity.com',
  cdn: 'https://cdn.unblockthecity.com',
};

// URLs de LOCAL
const LOCAL_URLS = {
  api: 'http://127.0.0.1:88',
  cdn: 'https://cdn.unblockthecity.com',
};

// URL de API
export const API_URL = (() => {
  switch (environment.deploy) {
    case DeployEnvironment.Production:
      return PRODUCTION_URLS.api;
    case DeployEnvironment.Stage:
      return STAGE_URLS.api;
    case DeployEnvironment.Develop:
      return DEV_URLS.api;
    case DeployEnvironment.Local:
      return LOCAL_URLS.api;
    default:
      return DEV_URLS.api;
  }
})();

// URL de CDN
export const CDN_URL = (() => {
  switch (environment.deploy) {
    case DeployEnvironment.Production:
      return PRODUCTION_URLS.cdn;
    case DeployEnvironment.Stage:
      return STAGE_URLS.cdn;
    case DeployEnvironment.Develop:
      return DEV_URLS.cdn;
    case DeployEnvironment.Local:
      return LOCAL_URLS.cdn;
    default:
      return DEV_URLS.cdn;
  }
})();

export const PLATFORM_PRODUCT_TYPES = PLATFORM_PRODUCTS;
