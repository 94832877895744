import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomersRequest } from '../../customers/models/customersRequest.model';
import { CustomerService } from '../../../services/customers.service';
import { Cliente } from '../../../models/cliente';
import { Administracion } from '../../../models/administracion';
import { NotificationService } from '../../../services/notification.service';
import { TaskService } from '../../services/task.service';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-events-customers',
  templateUrl: './events-customers.component.html',
  styleUrls: ['./events-customers.component.css']
})
export class EventsCustomersComponent implements OnInit {
  @Input()
  public searchTerm: string;

  public customers: Array<Cliente> = [];
  public destinations: Array<Administracion> = [];
  public selectedCustomer: Cliente;
  public selectedAdmin: Administracion;
  public isAdmin = false;

  constructor(
    public activeModal: NgbActiveModal,
    private notification: NotificationService,
    private customerService: CustomerService,
    private task: TaskService,
    private auth: AuthenticationService
  ) {
    this.isAdmin = this.auth.currentUserValue.user.rolId === 1;
  }

  public ngOnInit() {
    this.searchUsers();
  }

  public get hasResults(): boolean {
    return this.customers.length > 0 || this.destinations.length > 0;
  }

  public get resultCount(): number {
    return this.customers.length + this.destinations.length;
  }

  public selectCustomer( customer, type) {
    if (type === 0) {
      this.selectedCustomer = customer;
      this.selectedAdmin = null;

      for (let i = 0; i < this.customers.length; i++) {
        if (this.customers[i].id === this.selectedCustomer.id) {
          this.customers[i].selected = true;
        } else {
          this.customers[i].selected = false;
        }
      }

      for (let i = 0; i < this.destinations.length; i++) {
          this.destinations[i].selected = false;
      }
    } else {
      this.selectedAdmin = customer;
      this.selectedCustomer = null;

      for (let i = 0; i < this.destinations.length; i++) {
        this.destinations[i].selected = this.destinations[i].id === this.selectedAdmin.id;
      }

      for (let i = 0; i < this.customers.length; i++) {
        this.customers[i].selected = false;
      }
    }
  }

  public searchUsers() {
    this.task.run( () => {
      return this.doSearch()
        .then(
          (pagination: any) => {
            this.customers = pagination.clientes;
            this.destinations = pagination.administraciones;
          }
        );
      },

      'Error buscando el cliente/destino'
    );
  }

  public selected() {
    if (this.selectedCustomer || this.selectedAdmin) {
      this.closeModal({
        customer: this.selectedCustomer,
        destination: this.selectedAdmin
      });
    } else {
      this.notification.error( 'No has seleccionado ningun cliente');
    }
  }

  public closeModal( data?: any) {
    this.activeModal.close( data);
  }

  private doSearch(): Promise<any> {
    return this.customerService.search( this.buildCustomerRequest())
      .toPromise();
  }

  private buildCustomerRequest() {
    const result = new CustomersRequest();

    if (this.searchTerm && this.searchTerm.trim() !== '') {
      result.setNombre( this.searchTerm);
    }

    result.setUser(true);

    return result;
  }
}
